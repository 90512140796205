<template>
    <v-app class="desktopLayoutClass">
        <header class="headingContainer">
            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" style="cursor: pointer;"  @click="takeMeBack()"/>
            <h3>Nurse Booking</h3>
        </header>
        <div class="appointmentsParentContainer">
            <div class="upcomingCompletedTabContainer">
                <v-tabs v-model="tab" background-color="white" fixed-tabs style="border: white solid 2px;"
                    class="appointmentTab">
                    <v-tabs-slider color="transparent"></v-tabs-slider>
                    <v-tab v-for="item in items" :key="item.tab" style="width: 50%; border-radius: 4px;"
                        active-class="activeClass">
                        <span style="text-transform: capitalize;">{{ item }}</span>
                    </v-tab>
                </v-tabs>
            </div>
            <div v-if="tab == 0" style="width: 100%;height: 100%;">
                <upcomingAppointments></upcomingAppointments>
            </div>
            <div v-if="tab == 1" style="width: 100%;height: 100%;">
                <completedAppointment></completedAppointment>
            </div>
        </div>
    </v-app>
</template>
<script>
import upcomingAppointments from '../../components/nurseBookingComponents/upcomingAppointment.vue'
import completedAppointment from '../../components/nurseBookingComponents/completedAppointments.vue'
import { mixinFunctions } from '../../mixins/index'
export default {
    name: 'appointments',
    mixins:[mixinFunctions],
    components: {
        upcomingAppointments,
        completedAppointment
    },
    data() {
        return {
            items: ['Upcoming', 'Completed'],
            tab: ''
        }
    },
    methods:{
        takeMeBack(){
            this.$router.push({
                path: '/customer/profile'
            })
        }
    },
    mounted(){
        this.userPageViewTable('NBAllAppointments');
    }
}
</script>
<style scoped>
.activeClass {
    border-radius: 8px !important;
    color: white !important;
    background-color: #48ACEF !important;
}

.upcomingCompletedTabContainer {
    width: 100%;
    height: 3.75rem;
    padding: 4px;
    border: solid 1px #D3DCFB;
    background-color: #fff;
    border-radius: 8px;
}

.appointmentsParentContainer {
    width: 100%;
    height: 100%;
    padding: 12px 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.headingContainer {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #E0E0E0 !important;
    background-color: #fff !important;
}
</style>
<style>
.appointmentTab .v-tabs-bar {
    height: 48px !important;
}
</style>

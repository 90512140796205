<template>
    <div class="parentClass">
        <div v-if="loader">
            <span>loading...</span>
        </div>
        <div v-else>
            <div v-if="appointmentDetails.length > 0" v-for="appointmentDetail of appointmentDetails"
                :key="appointmentDetail.bookingID">
                <appointmentsCard appointmentType="COMPLETED" :appointmentDetail="appointmentDetail">
                </appointmentsCard>
            </div>
            <div v-if="appointmentDetails.length === 0" class="bookingsUnavailable">
                <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/appointmentsNotFound.svg" />
                <h4>Bookings Unavailable!</h4>
                <span>No results found, Select your nurse and book appointment to see your upcoming bookings.</span>
                <v-btn outlined color="#48ACEF" width="100%" style="margin-top: 24px;" @click="gotoHome()">Go Home</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { getNurseDisplayType } from '../../helpers/nba.helper.js';
import appointmentsCard from './appointmentsCard.vue';
export default {
    name: 'upcomingAppointments',
    components: { appointmentsCard },
    data() {
        return {
            appointmentDetails: [],
            loader: false
        }
    },
    methods: {
        gotoHome() {
            if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
                this.$router.push({ path: '/' });
            }
        },
        async fetchAppointmentDetails() {
            try {
                this.loader = true
                let fetchUpcomingAppointmentResponse = await axios_auth_instance_nurseBooking.get("/pagewise/completedAppointments")
                this.appointmentDetails = fetchUpcomingAppointmentResponse.data.data

                let completedAppointmentsFormattedArray = [];
                this.appointmentDetails.forEach((appointment) => {
                    let appointmentObject = {
                        ...appointment,
                        nurseDisplayStatus: getNurseDisplayType(appointment.nurse_type),
                    };
                    completedAppointmentsFormattedArray.push(appointmentObject);
                });
                this.appointmentDetails = completedAppointmentsFormattedArray;       

                this.loader = false
            } catch (error) {
                console.log("fetchAllBookingPageDetails Error:", error);
                if (error.response.status === 404) {
                    this.appointmentDetails = []
                }
                if (error.response.status === 401) {
                    this.$cookies.remove('customerToken');
                    this.$router.push({
                        name: "Login",
                    });
                }
            }
        }
    },
    async mounted() {
        await this.fetchAppointmentDetails()
    }
}
</script>
<style scoped>
.parentClass {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

span {
    color: #828282 !important;
    font-size: 14px;
}

.bookingsUnavailable {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
</style>